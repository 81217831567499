<template>
  <a-modal
      :title="title"
      :width="520"
      :visible="visible"
      :confirmLoading="loading"
      :maskClosable="false"
      :centered="true"
      :footer="null"
      :destroyOnClose="true"
      @cancel="cancel"
  >
    <a-spin :spinning="loading">
      <div class="table-operator">
        <SearchForm ref="SearchForm" @handleSubmit="handleSeachSubmit"/>
      </div>
      <div style="display: block">
        <s-table
            ref="table"
            rowKey="vm_id"
            :loading="isLoading"
            :columns="columns"
            :data="loadData"
            :pageSize="15"
            :scroll="{y:450}"
        >
          <div slot="vm_type" slot-scope="text, item">
            <a-tag v-if="text==0" color="blue">保养</a-tag>
            <a-tag v-else-if="text==1" color="red">维修</a-tag>
            <a-tag v-else>其他</a-tag>
          </div>
          <div slot="project" slot-scope="text, item">
            <a>{{ text }}</a>
          </div>
          <div slot="amount" slot-scope="text, item">
            <span style="color: rgb(208, 5, 5)">￥</span>{{ text }}
          </div>
          <div slot="kilometers" slot-scope="text, item">
            <div v-if="text">{{ text }}km</div>
          </div>
          <div slot="source" slot-scope="text, item">
            {{ text == 1 ? '后台' : '小程序' }}
          </div>
          <div class="actions" slot="action" slot-scope="text, item">
            <a-popconfirm
                title="确认删除当前记录？"
                ok-text="确定"
                cancel-text="取消"
                @confirm="handleDelete(item)"
            >
              <a href="#">删除</a>
            </a-popconfirm>
          </div>
        </s-table>

      </div>
    </a-spin>
  </a-modal>
</template>
<script>
import * as Api from '@/api/enterprise/systemViewlog'
import {STable} from "@/components";
import SearchForm from "./modules/SearchForm";
import SaveForm from "./modules/SaveForm";

export default {
  name: "viewlogModel",
  components: {SearchForm, SaveForm, STable},
  data() {
    return {
      title: '查阅记录',
      queryParam: {
      },
      visible: false,
      loading: false,
      isLoading: false,
      columns: [
        {
          title: '查阅人',
          dataIndex: 'personnel.personnel_name',
        },
        {
          title: '查阅时间',
          dataIndex: 'create_time',
          width: 150,
        },
        {
          title: '操作',
          dataIndex: 'action',
          fixed: 'right',
          scopedSlots: {customRender: 'action'}
        },
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: param => {
        return Api.list({...param, ...this.queryParam})
            .then(response => {
              return response.data.list
            })
      }
    }
  },
  methods: {
    open(system_id) {
      this.queryParam.system_id = system_id
      this.visible = true
    },
    cancel() {
      this.visible = false
    },

    /**
     * 添加
     */
    handleAdd() {
      this.$refs.SaveForm.add()
    },

    /**
     * 编辑
     */
    handleEdit(item) {
      this.$refs.SaveForm.edit(item)
    },

    handleDetails() {

    },

    handleDelete(item) {
      Api.deleted({viewlog_id: item['viewlog_id']})
          .then((result) => {
            this.$message.success(result.message, 1.5)
            this.handleRefresh()
          })
    },

    /**
     * 搜索
     */
    handleSeachSubmit(value) {
      this.queryParam = {
        ...this.queryParam,
        ...value
      }
      this.handleRefresh(true)
    },


    /**
     * 刷新列表
     * @param Boolean bool 强制刷新到第一页
     */
    handleRefresh(bool = false) {
      this.$refs.table.refresh(bool)
    },
  }
}
</script>
<style scoped lang="less">

</style>
