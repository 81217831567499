<template>
  <a-modal
      :title="title"
      :width="1420"
      :visible="visible"
      :confirmLoading="confirmLoading"
      :maskClosable="false"
      :centered="true"
      @ok="handleSubmit"
      @cancel="handleCancel"
  >
    <a-spin :spinning="confirmLoading">
      <a-form :form="form">
        <a-form-item label="制度标题" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input
              style="width: 300px"
              placeholder="请输入制度标题"
              v-decorator="['system_title', {rules: [{required: true, message: '请输入制度标题'}]}]"
          />
        </a-form-item>
        <a-form-item label="制度内容" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <Ueditor
              style="width: 100%"
              v-decorator="['system_content']"
          />
        </a-form-item>
        <a-form-item label="附件" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <UploadFile
              :textLength="50"
              :file="record.annex_file"
              accept=".pdf,.doc,.docx"
              v-decorator="['annex_file_id']"
              @change="readFileInfo"
          />
        </a-form-item>
      </a-form>
    </a-spin>
    <a-modal
        title="制度内容预览"
        :width="720"
        :visible="visible2"
        :confirmLoading="confirmLoading"
        :maskClosable="false"
        :centered="true"
        @ok="handleSubmit"
        @cancel="visible2=false"
    >
      <div style="min-height: 500px" v-html="html"></div>
    </a-modal>
  </a-modal>
</template>
<script>
import * as Api from '@/api/enterprise/system'
import _ from "lodash";
import {Ueditor, UploadFile, UploadImage} from "@/components";
import convertFileToHtml from "@/utils/convertFileToHtml";
import {isEmpty} from "@/utils/util";

export default {
  components: {UploadImage, Ueditor, UploadFile},
  data() {
    return {
      // 对话框标题
      title: '',
      // 标签布局属性
      labelCol: {
        span: 2
      },
      // 输入框布局属性
      wrapperCol: {
        span: 22
      },
      // modal(对话框)是否可见
      visible: false,
      // modal(对话框)确定按钮 loading
      confirmLoading: false,
      // 当前表单元素
      form: this.$form.createForm(this),
      // 当前记录
      record: {},
      // 当前模式 add新增 edit编辑
      mode: "",

      personnelList: [],

      visible2: false,
      html: ''
    }
  },
  methods: {
    async readFileInfo(e, info, file) {
      console.log(file)
    },
    /**
     * 显示对话框
     */
    add() {
      this.mode = "add"
      this.title = '新增制度'
      this.visible = true
    },
    edit(record) {
      this.mode = "edit"
      this.title = '编辑制度'
      this.visible = true

      this.record = record
      this.setFieldsValue()
    },

    /**
     * 设置默认值
     */
    setFieldsValue() {
      const {form: {setFieldsValue}} = this
      this.$nextTick(() => {
        const data = _.pick(this.record, ['system_title', 'system_content', 'annex_file_id'])
        setFieldsValue(data)
      })
    },

    handleSubmit2() {

    },

    /**
     * 确认按钮
     */
    handleSubmit(e) {
      const app = this
      const {form: {validateFields}} = this
      // if (this.form.getFieldValue('system_content')) {
      //   this.visible2 = true
      //   this.html =  this.form.getFieldValue('system_content')
      // }

      // if(!isEmpty(this.form.getFieldValue('annex_file_id'))){
      //   const modal = this.$confirm({
      //     title: '温馨提示?',
      //     content: '检测到您当前已上传word文件，是否对起进行加盖公章',
      //     onOk() {
      //       e.preventDefault()
      //       // 表单验证
      //       validateFields((errors, values) => {
      //         // 提交到后端api
      //         !errors && app.onFormSubmit(values)
      //       })
      //       modal.destroy()
      //     }
      //   })
      // }else{
      e.preventDefault()
      // 表单验证
      validateFields((errors, values) => {
        // 提交到后端api
        !errors && app.onFormSubmit(values)
      })
      // }


    },

    /**
     * 关闭对话框事件
     */
    handleCancel() {
      this.visible = false
      this.form.resetFields()
    },

    /**
     * 提交到后端api
     */
    onFormSubmit(values) {
      this.confirmLoading = true
      var sendApi = null
      if (this.mode == "add") {
        sendApi = Api.add({form: values})
      } else {
        sendApi = Api.edit({system_id: this.record['system_id'], form: values})
      }
      sendApi.then((result) => {
        // 显示成功
        this.$message.success(result.message, 1.5)
        // 关闭对话框
        this.handleCancel()
        // 通知父端组件提交完成了
        this.$emit('handleSubmit', values)
      })
          .finally((result) => {
            this.confirmLoading = false
          })
    }

  }
}
</script>
